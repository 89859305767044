body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-drawer-content-wrapper {
    width: 500px
}

@media only screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}

.order-heading-wrapper {
    display: flex;
    justify-content: space-between;
}

.order-description-item-wrapper-heading {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
    line-height: 1.5715;
}

.order-description-item-wrapper {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    display: flex;
    line-height: 1.5715;
}

.order-description-item-label {
    display: inline-block;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.client-orders-wrapper:hover {
    cursor: pointer;
    text-decoration: underline;

}

.ant-form-item {
    margin-bottom: 10px !important;
}

.form-item-serial-checkbox {
    margin-bottom: 0 !important;
}

.dashboard__card {
    padding: 10px;
}

.dashboard__range-picker {
    padding: 0 10px;
}

/* Finances Tabs Styles */
.card-container p {
    margin: 0;
}

.card-container > .ant-tabs-card .ant-tabs-content {
    height: auto;
    margin-top: -16px;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
}

.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
}

[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}

[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}

[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
}

[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
}

