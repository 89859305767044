@media print {
    @page {
        size: A4 landscape;
        margin: 5mm;
    }
}

.print-reception-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}

.print-reception-firstTable {
    width: 50%
}

.print-reception-secondTable {
    width: 50%;
    padding: 20px
}

.print-reception-separator {
    height: 100%;
    width: 2px;
    background: black;
}

.print-reception-phoneNumber {
    font-size: 22px;
}


.print-guarantee-wrapper {

}

