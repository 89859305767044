.main-sms-container-not-connected {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-sms-not-connected-title {
    text-align: center;
}

.main-sms-content-container {
    margin: 15px 10px 10px 10px;
    height: 100%
}

.ant-tabs-content-left {
    height: 99%
}