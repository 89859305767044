.steps-content {
    min-height: 200px;
    margin-top: 16px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

.steps-action {
    margin-top: 24px;
}
.group-container{
    padding: 20px;
    background: white;
}